import React, {useContext} from 'react';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { mainSocialLinks, socialLinks } from '../../Data/data';
import Grow from '@material-ui/core/Grow';
// import Typewriter from "typewriter-effect";
import StyleContext from "../../Contexts/themeContext.jsx";
import './Social.css'

import SocialIcon from './SocialIcon';

// const iconColor = "#4A4A4A";
// const iconColorDark = "#a3a3a3"

export default function Social(props) {
    const [expanded, setExpanded] = React.useState(false);
    const otherSocialLinks = socialLinks.filter((socialLink) => ! mainSocialLinks.some((mainLink) => mainLink.name === socialLink.name ));
    const {isDark} = useContext(StyleContext);
    const themeClassModifier = isDark ?"-dark" :"";


    const Icon = () => {
        return expanded ? <ExpandLessIcon className={`expand-icon${themeClassModifier}`} onClick={handleChange} style={{verticalAlign: "bottom"}} /> : <ExpandMoreIcon className={`expand-icon${themeClassModifier}`} onClick={handleChange} style={{verticalAlign: "bottom"}} />;
    }

    const handleChange = () => {
        setExpanded((prev) => !prev);
    };
    
    return (
        <div className="has-text-centered py-6 mb-6" ref={props.innerRef}>
            <div className="social-container">
                <h3>Find Me Here</h3>
                {mainSocialLinks.map((det, index)=><SocialIcon key={`social-icon-${index}-${det.id}-${det.name}`} socialDetails={det} /> )}
                <Typography className="mt-5 py-1" variant="subtitle1" component="h3">Searching somewhere else?<Icon/></Typography>
                <Grow in={expanded}>
                    <div>
                        {otherSocialLinks.map((det, index)=><SocialIcon socialDetails={det} key={`social-icon-${index}-${det.id}-${det.name}`}/>)}
                    </div>
                </Grow>
            </div>
        </div>
    )
}
