import React, { useRef, useState, useEffect } from 'react';
import Home from './Pages/Home/Home';
import CustomNavBar from './Components/CustomNavBar/CustomNavBar';
import Footer from './Components/Footer/Footer';
import {StyleProvider} from './Contexts/themeContext';
import 'bulma/css/bulma.min.css';
import './App.css';
import './fonts.css';
import Scroll from './Components/ScrollToTop';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';


function App() {
  const [isDark, setisDark] = useState(false);

  const changeTheme = () => {
    setisDark((prev)=> !prev);
  };

  useEffect(() => {
    const darkPref = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
    setisDark(darkPref.matches);
  }, []);

  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Montserrat', 'Open Sans',
      ].join(','),
    },});

  const introRef = useRef(null);
  const educationRef = useRef(null);
  const projectsRef = useRef(null);
  const coursesRef = useRef(null);
  const experienceRef = useRef(null);
  const techStackRef = useRef(null);
  const extraCurricularRef = useRef(null);
  const otherActivitiesRef = useRef(null);
  const socialRef = useRef(null);

  const scrollTo = (ref) => {
    window.scroll({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  }

  const refs = {
    introRef:introRef,
    educationRef:educationRef,
    projectsRef:projectsRef,
    coursesRef:coursesRef,
    experienceRef:experienceRef,
    techStackRef:techStackRef,
    extraCurricularRef:extraCurricularRef,
    otherActivitiesRef:otherActivitiesRef,
    socialRef:socialRef,
  };
  return (
    <div className={`App ${isDark ? "dark-mode" : ""}`}>
      <ThemeProvider theme={theme}>
      <StyleProvider
        value={{isDark: isDark, changeTheme: changeTheme}}
      >
        <CustomNavBar scrollTo={scrollTo} refs={refs}/>
        <Home refs={refs}/>
        <Scroll showBelow={250} />
        <Footer/>
      </StyleProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
