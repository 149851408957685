import React, {useContext} from 'react';
import './Footer.css';

import StyleContext from '../../Contexts/themeContext';

export default function Footer() {
    const {isDark} = useContext(StyleContext);
    const themeClassModifier = isDark ? "-dark":"";
    return (
        <footer className={`has-text-centered custom-footer${themeClassModifier} pt-3`}>
            <h1>Rajan Gaul</h1>
            <h2 className="footer-years">2000 - <span className={`infinity${themeClassModifier}`}>∞</span></h2>
            <h2 className={`made-with${themeClassModifier} pb-2`}>Made with <span className="heart-emoji">❤</span> using React, Material-UI and Bulma</h2>
        </footer>
    )
}
