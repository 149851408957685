import React, {useContext, useState} from 'react';
import TimelineItem from '@material-ui/lab/TimelineItem';
import Typography from '@material-ui/core/Typography';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Tooltip from '@material-ui/core/Tooltip';
import RoomIcon from '@material-ui/icons/Room';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';

import { Heading } from 'react-bulma-components';
import './Education.css';
import StyleContext from '../../Contexts/themeContext';

export default function TimelineCard({details, isLast}) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [expanded, setExpanded] = useState('');
    const [shadow, setShadow] = useState(1);

    const {isDark} = useContext(StyleContext);  

    const themeClassModifier = isDark ?"-dark" :"";
    const nbsp = '\u00A0';
    const bullet = '\u2023';
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleClose = () => {
        setTooltipOpen(false);
    };
    
    const handleOpen = () => {
        if(! details.isComplete) setTooltipOpen(true);
    };
    
    const onMouseOver = () => setShadow(3);
    const onMouseOut = () => setShadow(1);

    return (
        <div>
            <TimelineItem>
                <TimelineSeparator>
                    <Tooltip arrow 
                      title="Currently Pursuing" 
                      placement="bottom-end" 
                      open={tooltipOpen} 
                      onClose={handleClose} 
                      onOpen={handleOpen}>
                        <TimelineDot variant={details.isComplete ? "default" : "outlined"} className={`${isDark?"darkModeIcon":""}`}>
                            {details.icon}
                        </TimelineDot>
                    </Tooltip>
                        {!isLast && <TimelineConnector />}
                </TimelineSeparator>

                <Grid className="containerColumn my-2 ml-3"
                  item 
                  xs={12} sm={9} md={7} lg={6}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  zdepth={shadow}>
                    <Accordion className={`bordered timeline-accordian${themeClassModifier}`} 
                      expanded={expanded === 'panel1'} 
                      onChange={handleChange('panel1')}>
                        <AccordionSummary 
                          aria-controls={`${details.institute}-${details.studied}-panel1d-content`} 
                          id={`${details.institute}-${details.studied}-panel1d-header`}
                          expandIcon={<ExpandMoreIcon className={`accordian-expand-icon${themeClassModifier}`}/>}
                          >
                            <Grid container className="summaryContainerRow">
                                <Grid className="leftColumn has-text-left pr-2" 
                                  item 
                                  xs={12} sm={10}>
                                    <Typography variant="h6" component="h3">{details.institute}</Typography>
                                    <Heading  textWeight="bold" heading>{details.studied}</Heading>
                                    <Heading  heading className={`non-capital-heading institute-address${themeClassModifier}`}>{details.address} <span><a href={details.addressLink} target = "_blank" rel = "noopener noreferrer" aria-label={`Link to open ${details.institute} on Google Maps`}><RoomIcon className="map-marker" style={{fontSize: '15px', color:"#AAAAAA"}}/></a></span></Heading>
                                </Grid>
                                <Grid className="rightColumn has-text-left" 
                                  item 
                                  xs={12} sm={2}>
                                    <Typography variant="h6" component="h3">{details.score}</Typography>
                                    <Heading className={`education-date${themeClassModifier}`} heading>{details.startYear}-{details.completionYear}</Heading>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container className="detailsContainerRow">
                                <Grid className="leftColumn has-text-left pr-2"  
                                  item 
                                  xs={11} sm={12}>
                                    {details.highlights.map((highlight, idx)=> <Heading key={`Highlight+${idx}`} textWeight="bold" className="non-capital-heading" heading>{bullet}{nbsp}{highlight}</Heading>)}
                                </Grid>
                                {/* <Grid className="rightColumn" item xs={12} sm={3}>
                                    <Typography variant="h6" component="h3">{details.score}</Typography>
                                    <Heading  heading>{details.startYear}-{details.completionYear}</Heading>
                                </Grid> */}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </TimelineItem>
        </div>
    )
}
