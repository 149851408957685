import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import Chip from '@material-ui/core/Chip';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import './Projects.css';
import StyleContext from '../../Contexts/themeContext';

export default function ProjectCard({details, isLast}) {
    const {isDark} = useContext(StyleContext);
    const themeClassModifier = isDark?"-dark":"";

    return (
        <div>
            <Card raised={false} className={`project-card${themeClassModifier}`} style={{boxShadow:"none",borderRadius:"10px"}}>
                {/* <CardMedia
                    component="img"
                    image={im}
                    title="Live from space album cover"
                /> */}
                <CardContent>
                    <Grid container className="pb-4"  direction="row" justify="space-between">
                        <Grid item>
                            <Typography variant="h6" component="h3" className={`card-head`}>{details.name}</Typography>
                        </Grid>
                        <Grid item>
                            <a 
                              href={details.repo} 
                              target = "_blank" rel = "noopener noreferrer"
                              aria-label={`Link to the GitHub repository of ${details.name}`}>
                                <FontAwesomeIcon  icon={faGithub} color={isDark?"#fcfcfc":"#4A4A4A"} size='lg'/>
                            </a>
                        </Grid>
                    </Grid>
                    <Divider className={`divider${isDark?"-dark":""} mb-3`}/>
                    <div className="pb-3">
                        <Typography size={5} className={`pr-5 card-subhead-1`} textweight="normal" >{details.description}</Typography>
                    </div>
                        {details.technologies.map((tech, index)=><Chip key={`${details.name}-${tech}-${index}`} className={`mr-3 my-1 chip${themeClassModifier}`} label={tech} variant="outlined" size="small"/>)}
                    </CardContent>
            </Card>
        </div>
    )
}
