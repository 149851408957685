import { Typography } from '@material-ui/core';
import React, {useState} from 'react';
import CircleLoader from "react-spinners/CircleLoader";

export default function LastFMCard(props) {
    const [isLoading, setisLoading] = useState(true);
    const [isError, setisError] = useState(false);

    return (
        <div style={{minHeight:"216px"}}>
            {isLoading && <CircleLoader loading={isLoading}/>}
            <div style={{display:"inline-block"}}>
                <a  href="http://last.fm/user/mahan_vyakti/" target = "_blank" rel = "noopener noreferrer" ><img src="https://lastfm-recently-played.vercel.app/api?user=mahan_vyakti&count=2&width=375" alt="Last.fm recent tracks" onLoad={()=>setisLoading(false)} onError={()=>setisError(true)}></img></a>
                {!isLoading && !isError && <p className={`lfm-card-credits`} style={{textAlign:"right"}}>Card By: <a href="https://github.com/JeffreyCA/lastfm-recently-played-readme" target = "_blank" rel = "noopener noreferrer" className={`guni-sheru${props.themeClassModifier}`} >Jeffery Chen</a></p>}
            </div>
            {isError&&<Typography>Oh No! Something went wrong! Please refresh or visit my <a href="http://last.fm/user/mahan_vyakti/" target = "_blank" rel = "noopener noreferrer" className={`guni-sheru${props.themeClassModifier}`} >Last.fm </a> directly.</Typography>}
        </div>
    )
}
