import React, {useContext} from 'react'
import {Container, Heading} from 'react-bulma-components';
import { techStack } from '../../Data/data';
import TechStackTable from './TechStackTable';

import StyleContext from '../../Contexts/themeContext';

export default function TechStack(props) {
    const {isDark} = useContext(StyleContext);  

    const themeClassModifier = isDark ?"-dark" :"";
    return (
        <div ref={props.innerRef}>
            <section className="section is-small">
                <Container>
                    <Heading textAlign="left" className={`sec-head${themeClassModifier}`}>
                        Tech Stack
                    </Heading>
                    <TechStackTable techStack={techStack} isDark={isDark}/>
                </Container>
        </section>
        </div>
    )
}
