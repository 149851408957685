import React from 'react'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Heading } from 'react-bulma-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';



export default function ExperienceCard({details, isLast, isDark}) {
    const nbsp = '\u00A0';
    const bullet = '\u2023';
    const listItem = (text, index)=>{
        return(
            <ListItem 
              key={`bullet-list-point-${index}-for-${details.id}${details.title}`}
              className="pl-3">
                <ListItemText primary={`${bullet}${nbsp}${text}`} />
            </ListItem>
        );
        
    }
    
    return (
        <div>
            <Card 
              raised={false} 
              className={`experience-card${isDark?"-dark":""}`} 
              style={{boxShadow:"none", borderRadius:"10px"}}
              >
                <CardContent>
                    <Grid container justify="space-between">
                        <Grid 
                          xs={9} 
                          item
                          key={`${details.title}-exp-details-card-grid1-with-org-title`} 
                        >
                            <Typography 
                              className="pl-2" variant="h6" 
                              component="h3" 
                              style={{minHeight:"75px"}}
                              >
                                {details.title}
                            </Typography>
                            <Heading 
                              className="pb-2 pl-2" 
                              textWeight="bold" 
                              heading
                              >
                                {details.organization}
                            </Heading>
                        </Grid>
                        <Grid 
                          className="p-1" xs={2}
                          item 
                          key={`${details.title}-exp-details-card-grid2-with-${details.organization}`} 
                          >
                            <img src={details.image} alt={`${details.organization} logo`} />
                        </Grid>
                    </Grid>
                    <Divider className={`divider${isDark?"-dark":""}`}/>
                    {/* <Grid container justify="space-between">
                        <Grid>
                            <Typography>
                                {details.organization}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Heading  heading>
                                {Intl.DateTimeFormat("en-GB", {
                                                year: "numeric", month: "long", day: "2-digit" }).format(details.startDate)}-{Intl.DateTimeFormat("en-GB", {
                                                    year: "numeric", month: "long", day: "2-digit" }).format(details.endDate)}</Heading>
                        </Grid>
                    </Grid> */}
                    <div >
                        <List dense={true}>
                            {details.highlights.map(listItem)}
                        </List>
                    </div>
                    <Grid 
                      className="px-4"
                      container 
                      key={`${details.title}-exp-details-card-grid3-with-chips`} 
                      >
                        {details.technologies.map((tech, index)=>
                            <Chip 
                              key={`exp-details-chip-${details.title}-tech-${tech}-${index}`} 
                              className={`mr-3 my-1 chip${isDark?"-dark":""}`} 
                              label={tech} 
                              variant="outlined" 
                              size="small"
                            />
                    )}
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}
