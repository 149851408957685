import React, { useState, useContext, useEffect } from "react";
import StyleContext from "../../Contexts/themeContext";
import "./Toggle.css";

const ToggleSwitch = () => {
    const [isChecked, setChecked] = useState(false);
    const styleContext = useContext(StyleContext);
    useEffect(() => {
        const darkPref = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
        setChecked(darkPref.matches);
    }, []);

    const triggerToggle = () => {
        styleContext.changeTheme();
        setChecked(!isChecked);
        };

    return (
        // <label className="switch">
        //     <input
        //     type="checkbox"
        //     checked={isChecked}
        //     onChange={() => {
        //         styleContext.changeTheme();
        //         setChecked(!isChecked);
        //         }
        //     }
        // />
        // <span className="slider round"></span>
        // </label>
        <div onClick={triggerToggle}
                className={`wrg-toggle ${isChecked ? 'wrg-toggle--checked' : ''}`}>
            <div className="wrg-toggle-container">
                <div className="wrg-toggle-check">
                    <span>🌜</span>
                </div>
                <div className="wrg-toggle-uncheck">
                    <span>🌞</span>
                </div>
            </div>
            <div className="wrg-toggle-circle"></div>
            <input className="wrg-toggle-input" type="checkbox" aria-label="Toggle Button" />
        </div>
    );
};
export default ToggleSwitch;