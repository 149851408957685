import React, {useContext} from 'react'
import { Heading, Container } from 'react-bulma-components'
import Grid from '@material-ui/core/Grid';

import './Projects.css';
import { projectDetails } from '../../Data/data';
import ProjectCard from './ProjectCard';

import StyleContext from '../../Contexts/themeContext';
import { Typography } from '@material-ui/core';

export default function Projects(props) {
    const {isDark} = useContext(StyleContext);
    const themeClassModifier = isDark ? "-dark" : "";

    return (
        <div ref={props.innerRef}>
            <section className="section is-small">
                <Container>
                    <Heading textAlign="left" className={`sec-head${themeClassModifier}`}>
                        Projects
                    </Heading>
                    <Grid className="px-5" direction="row" container spacing={4}>
                        {projectDetails.map((detail, index)=>
                            detail.display && <Grid item xs={12} sm={6} md={4} lg={3} key={`{detail.key}-${index}`}>
                                <div className={`bordered project-card${themeClassModifier}`} style={{height: "100%", backgroundColor:"#FFF"}}>
                                    <ProjectCard details={detail} isLast={index === projectDetails.length-1}/>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    <Typography className={`px-5 mt-5`}>Check out more projects on <a href={`https://github.com/mahanvyakti`} className={`more-github-projects${themeClassModifier}`}>my GitHub profile</a>.</Typography>
                </Container>
            </section>
        </div>
    )
}
