import {
    faGithub,
    faLinkedin,
    faLastfm,
    faHackerrank,
    faYoutube,
    // faDiscord
} from "@fortawesome/free-brands-svg-icons";

import CreateIcon from '@material-ui/icons/Create';
import SchoolIcon from '@material-ui/icons/School';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

import digiliyoLogo from "../digiliyo-square.jpg";
import kjsceLogo from "../kjsce-square.png";

import * as Icons from "../Icons/index.js";
import SocialIcons from "../Icons/SocialIcons.js";

const originalSocialLinks = {
    gmail:          "rajan.gaul123@gmail.com",
    github:         "https://github.com/mahanvyakti",
    linkedin:       "https://www.linkedin.com/in/rajangaul/",
    stackoverflow:  "https://stackoverflow.com/users/13365850/mahan-vyakti",
    instagram:      "https://www.instagram.com/mahan_vyakti/",
    twitter:        "https://twitter.com/RajanGaul",
    discord:        "https://discordapp.com/users/704632958958567535",
    spotify:        "https://open.spotify.com/user/a46k9pm7npcy0t9sxfkm4n2ar",
    lastFM:         "http://last.fm/user/mahan_vyakti/",
    reddit:         "https://www.reddit.com/user/mahan_vyakti",
    strava:         "https://www.strava.com/athletes/26497582",
    codechef:       "https://www.codechef.com/users/mahan_vyakti",
    hackerrank:     "https://www.hackerrank.com/mahan_vyakti",
    leetcode:       "https://leetcode.com/mahan_vyakti/",
    googleMaps:     "https://www.google.com/maps/contrib/115621750217510184199",
    openStreetMap:  "https://www.openstreetmap.org/user/mahan%20vyakti",
    shutterStock:   "https://www.shutterstock.com/g/Rajan+Gaul",
    youtube:        "https://www.youtube.com/channel/UCubCDUxgaILu-vHoVZzFi6g",
};

const socialLinks = [
    {
        id:     "gmail-icon",
        name:   "Gmail",
        link:   "mailto:rajan.gaul123@gmail.com",
        icon:   SocialIcons.Gmail,
        isFAIcon: false,
    },    
    {
        id:     "github-icon",
        name:   "GitHub",
        link:   originalSocialLinks.github,
        icon:   faGithub,
        isFAIcon: true,
    },
    {
        id:     "linked-icon",
        name:   "LinkedIn",
        link:   originalSocialLinks.linkedin,
        icon:   faLinkedin,
        isFAIcon: true
    },
    {
        id:     "stack-overflow-icon",
        name:   "Stack Overflow",
        link:   originalSocialLinks.stackoverflow,
        icon:   SocialIcons.StackOverflow,
        isFAIcon: false,
    },
    {
        id:     "instagram-icon",
        name:   "Instagram",
        link:   originalSocialLinks.instagram,
        icon:   SocialIcons.Instagram,
        isFAIcon: false,
    },
    {
        id:     "twitter-icon",
        name:   "Twitter",
        link:   originalSocialLinks.twitter,
        icon:   SocialIcons.Twitter,
        isFAIcon: false,
    },
    {
        id:     "discord-icon",
        name:   "Discord",
        link:   originalSocialLinks.discord,
        icon:   SocialIcons.Discord,
        // icon:   faDiscord,
        // isFAIcon: true
    },
    {
        id:     "spotify-icon",
        name:   "Spotify",
        link:   originalSocialLinks.spotify,
        icon:   SocialIcons.Spotify,
    },
    {
        id:     "lastfm-icon",
        name:   "Last.FM",
        link:   originalSocialLinks.lastFM,
        icon:   faLastfm,
        isFAIcon: true
    },
    {
        id:     "reddit-icon",
        name:   "Reddit",
        link:   originalSocialLinks.reddit,
        icon:   SocialIcons.Reddit,
    },
    {
        id:     "strava-icon",
        name:   "Strava",
        link:   originalSocialLinks.strava,
        icon:   SocialIcons.Strava,
    },
    {
        id:     "codechef-icon",
        name:   "CodeChef",
        link:   originalSocialLinks.codechef,
        icon:   SocialIcons.CodeChef,
    },
    {
        id:     "hackerrank-icon",
        name:   "HackerRank",
        link:   originalSocialLinks.hackerrank,
        icon:   faHackerrank,
        isFAIcon: true
    },
    {
        id:     "leetcode-icon",
        name:   "LeetCode",
        link:   originalSocialLinks.leetcode,
        icon:   SocialIcons.LeetCode,
    },
    {
        id:     "googlemaps-icon",
        name:   "Google Maps",
        link:   originalSocialLinks.googleMaps,
        icon:   SocialIcons.GoogleMaps,
    },
    {
        id:     "openstreetmap-icon",
        name:   "OpenStreetMap",
        link:   originalSocialLinks.openStreetMap,
        icon:   SocialIcons.OpenStreetMap,
    },
    {
        id:     "shutterstock-icon",
        name:   "Shutterstock",
        link:   originalSocialLinks.shutterStock,
        icon:   SocialIcons.Shutterstock,
    },
    {
        id:     "youtube-icon",
        name:   "YouTube",
        link:   originalSocialLinks.youtube,
        icon:   faYoutube,
        isFAIcon: true
    },
];

const mainSocialLinks = [
    {
        id:     "github-main-icon",
        name:   "GitHub",
        link:   originalSocialLinks.github,
        icon:   faGithub,
        isFAIcon: true
        },
    {
        id:     "linkedin-main-icon",
        name:   "LinkedIn",
        link:   originalSocialLinks.linkedin,
        icon:   faLinkedin,
        isFAIcon: true
        },
    {
        id:     "twitter-main-icon",
        name:   "Twitter",
        link:   originalSocialLinks.twitter,
        icon:   SocialIcons.Twitter,
        },
    {
        id:     "instagram-main-icon",
        name:   "Instagram",
        link:   originalSocialLinks.instagram,
        icon:   SocialIcons.Instagram,
        },
    {
        id:    "stackoverflow-main-icon",
        name:  "Stack Overflow",
        link:  originalSocialLinks.stackoverflow,
        icon:  SocialIcons.StackOverflow,
        },
    {
        id:     "spotify-main-icon",
        name:   "Spotify",
        link:   originalSocialLinks.spotify,
        icon:   SocialIcons.Spotify,
        },
];



// const tracerSocialLinks = {
//     gmail:          "rajan.gaul123@gmail.com",
//     github:         "https://trcr.tk/aVTNH",
//     linkedin:       "https://trcr.tk/AB9og",
//     stackoverflow:  "https://trcr.tk/3AaST",
//     instagram:      "https://trcr.tk/AGZyy",
//     twitter:        "https://trcr.tk/RLUiN",
//     discord:        "https://trcr.tk/OJJbV",
//     spotify:        "https://trcr.tk/7w0n5",
//     lastFM:         "https://trcr.tk/LPhtp",
//     reddit:         "https://trcr.tk/3YOFV",
//     strava:         "https://trcr.tk/CCsXj",
//     codechef:       "https://trcr.tk/Pgt8c",
//     hackerrank:     "https://trcr.tk/KYSy4",
//     leetcode:       "https://trcr.tk/fOlPF",
//     googleMaps:     "https://trcr.tk/rcl6Z",
//     openStreetMap:  "https://trcr.tk/im8R6",
//     shutterStock:   "https://trcr.tk/R5NS6",
//     youtube:        "https://trcr.tk/vr3hk",
// };

const educationDetails = [
    {
        key:            "education-3",
        institute:      "K. J Somaiya College of Engineering",
        city:           "Mumbai",
        address:        "Vidyavihar, Mumbai, Maharashtra",
        addressLink:    "https://goo.gl/maps/PRe11VZFdgPAZPAa6",
        startYear:      "2018",
        completionYear: "2022",
        type:           "Degree",
        studied:        "B. Tech in Computer Engineering",
        score:          "9.2/10",
        scoerType:      "CGPA",
        branch:         "Computer Engineering",
        isComplete:     false,
        icon:           <SchoolIcon/>,
        highlights:     ["Highest SGPI: 9.61/10", "Subject topper in Digital System Design",],
    },
    {
        key:            "education-2",
        institute:      "Sir Parshurambhau College",
        city:           "Pune",
        address:        "Sadashiv Peth, Pune, Maharashtra",
        addressLink:    "https://goo.gl/maps/PoK8fMWZ8T4NU2Wo8",
        startYear:      "2016",
        completionYear: "2018",
        type:           "School",
        studied:        "Grade XI - XII",
        score:          "85%",
        scoerType:      "Percentage",
        branch:         "Science",
        isComplete:     true,
        icon:           <ImportContactsIcon/>,
        highlights:     ["Cleared JEE, BITSAT and MHT-CET with good scores" ],
    },
    {
        key:            "education-1",
        institute:      "Bal Vidyamandir Highschool",
        city:           "Parbhani",
        address:        "Nanal Peth, Parbhani, Maharahtra",
        addressLink:    "https://goo.gl/maps/CPtxn853KaK1LyUp7",
        startYear:      "2010",
        completionYear: "2016",
        type:           "School",
        studied:        "Grade IV - X",
        score:          "97%",
        scoerType:      "Percentage",
        branch:         "",
        isComplete:     true,
        icon:           <CreateIcon/>,
        highlights:     ["State Rank 4 in Highschool Scholarship Exam", "Won A.B. Highschool's State-level General Knowledge Competition", "Member of School's Music Team", "State Rank 6 in MTS"],
    },
    
];

const courses = [
    {
        id:                 "course-01",
        name:               "Deep Learning Specialization",
        completionDate:     "Sep. 2020",
        isSpecialization:   true,
        courseList:         [
            {
                id:     "course-01.1",
                name:   "Neural Networks and Deep Learning",
            },
            {
                id:     "course-01.2",
                name:   "Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization",
            },
            {
                id:     "course-01.3",
                name:   "Structuring Machine Learning Projects",
            },
            {
                id:     "course-01.4",
                name:   "Convolutional Neural Networks",
            },
            {
                id:     "course-01.5",
                name:   "Sequence Models",
            },
        ],
        offeredBy:          "DeepLearning.AI",
        platform:           "Coursera",
        instructor:         "Prof. Andrew Ng",
        instructorUrl:      "https://twitter.com/AndrewYNg",
        url:                "https://coursera.org/share/7246413fddc7eae648c43df0bafec14a",
        certificate:        "https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~DBATHCMT4PVT/CERTIFICATE_LANDING_PAGE~DBATHCMT4PVT.jpeg",
        technologies:       ["Tensorflow", "Convolutional Neural Networks", "Transformers", "Recurrent Neural Network"],
    },
    {
        id:                 "course-02",
        name:               "The Complete 2020 Web Development Bootcamp",
        completionDate:     "Oct. 2020",
        isSpecialization:   false,
        courseList:         [],
        offeredBy:          "London App Brewery",
        platform:           "Udemy",
        instructor:         "Dr. Angela Yu",
        instructorUrl:      "https://twitter.com/yu_angela",
        url:                "http://ude.my/UC-fd7ef135-0138-4729-aa9d-eb102bf9ec39",
        certificate:        "https://udemy-certificate.s3.amazonaws.com/image/UC-fd7ef135-0138-4729-aa9d-eb102bf9ec39.jpg?v=1611239511000",
        technologies:       ["TensorFlow.js", "Object Detection"],
    },
    {
        id:                 "course-03",
        name:               "Programming with Google Go",
        completionDate:     "Sep. 2020",
        isSpecialization:   true,
        courseList:         [
            {
                id:     "course-03.1",
                name:   "Getting Started with Go",
            },
            {
                id:     "course-03.2",
                name:   "Functions, Methods, and Interfaces in Go",
            },
            {
                id:     "course-03.3",
                name:   "Concurrency in Go",
            },
        ],
        offeredBy:          "University of California, Irvine",
        platform:           "Coursera",
        instructor:         "Ian Harris",
        instructorUrl:      "https://www.coursera.org/instructor/ianharris",
        url:                "https://coursera.org/share/7246413fddc7eae648c43df0bafec14a",
        certificate:        "https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~K4RA6WDDZJ8R/CERTIFICATE_LANDING_PAGE~K4RA6WDDZJ8R.jpeg",
        technologies:       ["Go"],
        
    },
    {
        id:                 "course-04",
        name:               "SQL for Data Science",
        completionDate:     "Jul. 2020",
        isSpecialization:   false,
        courseList:         [],
        offeredBy:          "University of California, Davis",
        platform:           "Coursera",
        instructor:         "Sadie St. Lawrence",
        instructorUrl:      "https://www.instagram.com/sadiestlawrence/",
        url:                "https://coursera.org/share/c8254b07f07b97472adc0c445115cc66",
        certificate:        "https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~3JCSJSG5WDTC/CERTIFICATE_LANDING_PAGE~3JCSJSG5WDTC.jpeg",
        technologies:       ["SQL", "Data Science"],
        
    },
    {
        id:                 "course-05",
        name:               "Browser-based Models with TensorFlow.js",
        completionDate:     "Oct. 2020",
        isSpecialization:   false,
        courseList:         [],
        offeredBy:          "DeepLearning.AI",
        platform:           "Coursera",
        instructor:         "Laurence Moroney",
        instructorUrl:      "https://twitter.com/lmoroney",
        url:                "https://coursera.org/share/328ba3b0dd5268f168d10105c859b39d",
        certificate:        "https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~3BK5CU264REG/CERTIFICATE_LANDING_PAGE~3BK5CU264REG.jpeg",
        technologies:       ["TensorFlow.js", "Object Detection"],
    },
];

//Daybook
//PGMS
//Attendance Tracker
//Bitflip
//Doubt Solver
//Socket-Chat
const projectDetails = [
    {
        id:             "project-daybook",
        name:           "Daybook",
        technologies:   ["Flutter", "Firebase"],
        // repo:           "https://trcr.tk/h92T0",
        repo:           "https://github.com/ARU-Coders/daybook",
        description:    "A beautiful journal app with mood and habit tracking and friendly interface",
        display:        true,
    },
    {
        id:             "project-pgms",
        name:           "PGMS",
        technologies:   ["Flask", "GeoJSON", "Flutter", "Firebase"],
        repo:           "https://github.com/The-AlphaDevs/PGMS-App/",
        description:    "PGMS: Public Grivances Management System is a platform for citizens of Mumbai to report grievances like potholes and get help from the concerned authorities from BMC.",
        display:        true,
    },
    {
        id:             "project-attendance-tracker",
        name:           "Attendance Tracker",
        technologies:   ["Flutter", "Firebase", "Flask"],
        // repo:           "https://trcr.tk/4HkM0",
        repo:           "https://github.com/mahanvyakti/RIP-Proxy",        
        description:    "An attendance marker app that identifies roll numbers of students present in the image",
        display:        true,
    },
    {
        id:             "project-bitflip",
        name:           "Modulebar",
        technologies:   ["Nuxt.js", "Vue", "Tailwind CSS"],
        // repo:           "https://trcr.tk/JlaYA",
        repo:           "https://www.modulebar.com/",
        description:    "One stop solution for learning, projects and internships",
        display:        true,
    },
    {
        id:             "project-doubt-solver",
        name:           "Doubt Solver",
        technologies:   ["Android", "Firebase"],
        // repo:           "https://trcr.tk/bJdcR",
        repo:           "https://github.com/mahanvyakti/Doubt-Solver",
        description:    "An android app that helps FY students in clearing their academic doubts",
        display:        true,
    },
    {
        id:             "project-socket-chat",
        name:           "Socket-chat!",
        technologies:   ["React", "Socket.io", "Node.js"],
        // repo:           "https://trcr.tk/jcwJW",
        repo:           "https://github.com/mahanvyakti/socket-chat",
        description:    "A simple app that allows user to create rooms and chat",
        display:        false,
    },
    {
        id:             "project-blockchain-basics",
        name:           "Blockchain Simulation",
        technologies:   ["Node.js", "crypto"],
        repo:           "https://github.com/mahanvyakti/blockchain-simulation",
        description:    "A simple CLI-application that demonstrates the basic concepts of blockchain and cryptocurrencies like Bitcoin.",
        display:        true,
    }
];

const experienceDetails = [
    {
        id:             "experience-01",
        title:          "Python Developer",
        organization:   "KJSCE, Mumbai",
        duration:       "1 Month",
        startDate:      "2020-05-01T00:00:00.000Z",
        endDate:        "2021-05-31T00:00:00.000Z",
        description:    "High Performance Computing And Its Use In Image Analytics.",
        image:          kjsceLogo,
        highlights:     ["Learned about the High Performance Computing Facility at IIT Delhi", "Created a script to grade hackathon submissions on HPCF"],
        repo:           "",
        technologies:   ["Python", "Pandas", "OpenCV"],
        display:        false
    },
    {
        id:             "experience-02",
        title:          "App Development and Deep Learning Intern",
        organization:   "KJSCE, Mumbai",
        duration:       "3 Months",
        startDate:      "2020-05-21T00:00:00.000Z",
        endDate:        "2020-08-31T00:00:00.000Z",
        description:    "",
        image:          kjsceLogo,
        highlights:     ["Worked with the datasets containing images of foreign & local crops leaves", "Developed a flutter app to collect images of crops for creating dataset", "Developed a flutter app and encorporated the trained model into it for offline prediction."],
        repo:           "https://trcr.tk/xSvPG",
        technologies:   ["Flutter", "Firebase", "Flask", "Tensorflow"],
        display:        true
    },
    {
        id:             "experience-03",
        title:          "Mobile Application Developer",
        organization:   "Digiliyo Technologies, Mumbai",
        duration:       "2 Months",
        startDate:      "2021-04-01T00:00:00.000Z",
        endDate:        "2021-05-31T00:00:00.000Z",
        description:    "",
        image:          digiliyoLogo,
        highlights:     ["Worked on RazorPay Integration", "Developed flutter apps with wallet functionality", "Integrated third-party API for mobile and DTH recharge"],
        repo:           "https://trcr.tk/wHWjQ",
        technologies:   ["Flutter", "Firebase", "Razorpay"],
        display:        true
    },

];

const techStack = [
    {
        id:         "techStack-001",
        name:       "C",
        type:       "Language",
        icon:       Icons.C,
        proficiency:85, 
    },
    {
        id:         "techStack-014",
        name:       "Python",
        type:       "Language",
        icon:       Icons.Python,
        proficiency:85, 
    },  
    {
        id:         "techStack-008",
        name:       "Java",
        type:       "Language",
        icon:       Icons.Java,
        proficiency:70, 
    },
    {
        id:         "techStack-006",
        name:       "Go",
        type:       "Language",
        icon:       Icons.Go,
        proficiency:50, 
    },
    {
        id:         "techStack-002",
        name:       "C++",
        type:       "Language",
        icon:       Icons.CPlusPlus,
        proficiency:50, 
    },
    {
        id:         "techStack-007",
        name:       "HTML",
        type:       "Web/App Development",
        icon:       Icons.HTML,
        proficiency:90, 
    },
    {
        id:         "techStack-003",
        name:       "CSS",
        type:       "Web/App Development",
        icon:       Icons.CSS,
        proficiency:70, 
    },
    {
        id:         "techStack-009",
        name:       "JavaScript",
        type:       "Web/App Development",
        icon:       Icons.JavaScript,
        proficiency:80, 
    },
    {
        id:         "techStack-013",
        name:       "Node.js",
        type:       "Web/App Development",
        icon:       Icons.NodeJS,
        proficiency:65, 
    },
    {
        id:         "techStack-004",
        name:       "Express.js",
        type:       "Web/App Development",
        icon:       Icons.ExpressJS,
        proficiency:70, 
    },
    {
        id:         "techStack-015",
        name:       "React",
        type:       "Web/App Development",
        icon:       Icons.ReactLogo,
        proficiency:70, 
    },
    {
        id:         "techStack-016",
        name:       "Vue",
        type:       "Web/App Development",
        icon:       Icons.Vue,
        proficiency:40, 
    },
    {
        id:         "techStack-005",
        name:       "Flutter",
        type:       "Web/App Development",
        icon:       Icons.Flutter,
        proficiency:75, 
    },
    {
        id:         "techStack-017",
        name:       "Git",
        type:       "Other",
        icon:       Icons.Git,
        proficiency:80, 
    },
    {
        id:         "techStack-018",
        name:       "GitHub",
        type:       "Other",
        icon:       Icons.GitHub,
        proficiency:75, 
    },
    {
        id:         "techStack-010",
        name:       "Jenkins",
        type:       "DevOps",
        icon:       Icons.Jenkins,
        proficiency:50, 
    },
    {
        id:         "techStack-011",
        name:       "MongoDB",
        type:       "Other",
        icon:       Icons.MongoDB,
        proficiency:75, 
    },
    {
        id:         "techStack-012",
        name:       "MySQL",
        type:       "Other",
        icon:       Icons.MySQL,
        proficiency:60, 
    },
    {
        id:         "techStack-019",
        name:       "TensorFlow",
        type:       "Other",
        icon:       Icons.TensorFlow,
        proficiency:75, 
    },
];

export {
    courses,
    educationDetails,
    experienceDetails,
    mainSocialLinks,
    originalSocialLinks,
    projectDetails,
    socialLinks,
    techStack,
};