import React, {useContext} from 'react'
import {Container, Heading } from 'react-bulma-components';
import {courses} from '../../Data/data';
import CourseDetails from "./CourseDetails";
import "./courses.css";
import StyleContext from '../../Contexts/themeContext';


import Grid from '@material-ui/core/Grid';
export default function Courses(props) {
    const {isDark} = useContext(StyleContext);  

    const themeClassModifier = isDark ?"-dark" :"";

    return (
        <div ref={props.innerRef}>
            <section className="section is-small">
                <Container>
                    <Heading textAlign="left" className={`sec-head${themeClassModifier}`}>
                        Certifications
                    </Heading>
                    <Grid container className="summaryContainerRow pl-5 pr-3">
                        <Grid className="leftColumn" item xs={12} sm={10} md={7} lg={6}>
                            {courses.map((course)=><CourseDetails course={course} key={`course-details-${course.name}`}/>)}
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </div>
    )
}
