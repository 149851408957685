import React, {useContext} from 'react'
import {Section, Container, Heading} from 'react-bulma-components';
import StyleContext from '../../Contexts/themeContext';
import { Typography } from '@material-ui/core';


import LastFMCard from './LastFMCard';
import "./OtherActivities.css";

export default function OtherActivities(props) {
    const {isDark} = useContext(StyleContext);
    const themeClassModifier = isDark?"-dark":"";

    return (
        <div ref={props.innerRef}>
            <Section size="small">
                <Container>
                    <Heading textAlign="left" className={`sec-head${themeClassModifier}`}>
                        About Me
                    </Heading>
                    <div className={`px-5`}>
                        <Typography className={`mb-3`}><span className={`italics`}>Mahan Vyakti</span> on the internet, Rajan in real life!</Typography>
                        <Typography variant="body1" className={`mt-1`}>I love capturing beautiful moments in the camera📷. I was a member of <span className={`italics`}>Shutterbugs</span>, the photography team of KJSCE.</Typography>
                        <Typography variant="body1" className={`mt-1`}>I also love biking🚲, reading good books📖, listening to music🎵 and exploring maps🗺️📌!</Typography>
                        <Typography variant="body1" className={`mt-1`}>You will find out that I am clearly a dog person, if you <a href="https://www.instagram.com/guni_sheru/" target = "_blank" rel = "noopener noreferrer"  className={`guni-sheru${themeClassModifier}`} >check this </a>!</Typography>
                        <div  className={`mt-4`}>
                            <Typography variant="body1" className={`mb-2`}>Here are my recently played tracks, you can find out more on my <a href="http://last.fm/user/mahan_vyakti/" target = "_blank" rel = "noopener noreferrer" className={`guni-sheru${themeClassModifier}`} >Last.fm </a>.</Typography>
                            <LastFMCard themeClassModifier={themeClassModifier}/>
                        </div>
                    </div>

                    {/* <ol className="px-5">
                        <li>Photography</li>
                        <li>Books</li>
                        <li><span className="heart-emoji">❤</span> Music</li>
                        <li><span className="heart-emoji">❤</span> Cycling</li>
                        <li><span className="heart-emoji">❤</span> Dogs</li>
                        <li>Tennis 🎾</li>
                        <li>Cricket 🏏</li>
                    </ol> */}
                </Container>
            </Section>
        </div>
    )
}
