import React from 'react'
import Typography from '@material-ui/core/Typography';
import { Container, Section, Heading } from 'react-bulma-components'
// import avtar from "../../avtar.png";

export default function Intro(props) {
    return (
        <div ref={props.innerRef}>
            <Section size="large" className="intro-section">
                <Container>
                    {/* <Image src={avtar} alt="avatar" size={128}/> */}
                    <Typography variant="h3" className="web-head pb-1">Hi! I'm Rajan Gaul</Typography>
                    <Heading heading textAlign="left" size={3} className="web-subhead">
                        A Flutter, MERN and DL enthusiast
                    </Heading>
                </Container>
            </Section>
        </div>
    )
}
