import React, {useContext} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyleContext from '../../Contexts/themeContext';
import './Social.css';

export default function SocialIcon(props) {
    const socialDetails = props.socialDetails;
    const {isDark} = useContext(StyleContext);
    
    const iconColor = "#4A4A4A";
    const iconColorDark = "#a3a3a3"
    
    var className = (socialDetails.isFAIcon != null && socialDetails.isFAIcon) ? `${socialDetails.name}${isDark?"-dark":""} ${isDark?"darkIcon":""} social ${isDark?"dark-icon":""} mx-1` : `iconify ${socialDetails.name}${isDark?"-dark":""} ${isDark?"darkIcon":""} social ${isDark?"dark-icon":""} mx-1`;

        return (
        <a key={socialDetails.id} href={socialDetails.link} aria-label={`Link to my ${socialDetails.name} profile`}>
            {   socialDetails.isFAIcon != null && socialDetails.isFAIcon
                ?
                <FontAwesomeIcon icon={socialDetails.icon} className={className} color={isDark? iconColorDark: iconColor} size='lg'/>
                :
                <span className={className} data-icon={socialDetails.icon} data-inline="true" ></span>
            }
        </a>
        );
}
