import React, {useContext} from 'react'
import {Container, Section, Heading } from 'react-bulma-components';
import Grid from '@material-ui/core/Grid';
import { experienceDetails } from '../../Data/data';
import ExperienceCard from './ExperienceCard';
import StyleContext from '../../Contexts/themeContext';

import './Experience.css';

export default function Experience(props) {
    const {isDark} = useContext(StyleContext);  

    const themeClassModifier = isDark ?"-dark" :"";
    return (
        <div ref={props.innerRef}>
            <Section size="small">
                <Container>
                    <Heading textAlign="left" className={`sec-head${themeClassModifier} pb-4`}>
                        Experience
                    </Heading>
                    <Grid 
                      className="px-5" 
                      container 
                      direction="row" 
                      key={`exp-details-outermost-grid`} 
                      spacing={4}
                      >
                        {experienceDetails.map((detail, index)=>
                            <Grid 
                              key={`exp-${detail.id}${detail.title}-${index}`} 
                              item xs={12} sm={6} lg={4} 
                              style={{padding:"0 16px",}} 
                              className={`pb-3`}
                              >
                                <div 
                                  className={`bordered experience-card${isDark?"-dark":""}`} 
                                  style={{height: "100%"}}
                                  >
                                    <ExperienceCard 
                                      details={detail} 
                                      isLast={index === experienceDetails.length-1} 
                                      isDark={isDark}
                                      />
                                </div>
                            </Grid>
                            )}
                    </Grid>
                </Container>
            </Section>
        </div>
    )
}
