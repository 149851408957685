const socialIcons = {
    GitHub          :   "logos:github-icon",
    Gmail           :   "logos:google-gmail",
    LinkedIn        :   "simple-icons:linkedin",
    StackOverflow   :   "logos:stackoverflow-icon",
    Instagram       :   "fa-brands:instagram",
    Twitter         :   "logos:twitter",
    Discord         :   "logos:discord-icon",
    Spotify         :   "bx:bxl-spotify",
    LastFm          :   "branico:lastfm",
    Reddit          :   "logos:reddit-icon",
    Strava          :   "fa-brands:strava",
    CodeChef        :   "simple-icons:codechef",
    HackerRank      :   "fa-brands:hackerrank",
    LeetCode        :   "cib:leetcode",
    GoogleMaps      :   "logos:google-maps",
    OpenStreetMap   :   "cib:openstreetmap",
    Shutterstock    :   "gg:shutterstock",
    YouTube         :   "logos:youtube-icon",
};

export default socialIcons;