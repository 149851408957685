import React from 'react'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Certificate(props) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setisLoading] = React.useState(true);
    const themeClassModifier = props.isDark ?"-dark" :"";
    
    const loadingIndicator = () => <ClimbingBoxLoader loading={isLoading} style={{marginLeft:"50%", zIndex: 2, position:"absolute !important"}}/>
    const handleOpen = () => setOpen(true);

    
    const handleClose = () => setOpen(false);
    
    const MyImage = ({ image }) => (
        <div>
        <LazyLoadImage
            className="bordered" 
            effect="blur"
            alt={`${props.courseName} certificate`} 
            onClick={handleOpen} 
            onLoad={()=>setisLoading(false)}
            afterLoad={()=>setisLoading(false)}
            style={{verticalAlign: "middle", zIndex: 1}}
            src={props.certificate} // use normal <img> attributes as props
            // height={image.height}
            // width={image.width}
            />
        </div>
    );
    
    return (
        <div>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                disableSelection
                defaultExpanded={[`Courses-TreeItem${props.courseName}`]}
                >
                <TreeItem nodeId={`Courses-TreeItem${props.courseName}`} label="Certificate">
                    <div className={`climbing-box-loader${themeClassModifier}`}  style={{minHeight:"350px",display: "flex", alignItems: "center", textAlign:"center", justifyContent: 'center', alignContent:"center"}}>
                        {isLoading && loadingIndicator()}
                        {/* {isLoading && <ClimbingBoxLoader loading={isLoading} style={{marginLeft:"50%", zIndex: 2, position:"absolute !important"}}/>}  */}
                        {/* <img style={{verticalAlign: "middle", zIndex: 1}} src={props.certificate} className="bordered" alt={`${props.courseName} certificate`} onClick={handleOpen} onLoad={()=>setisLoading(false)}/> */}
                        <MyImage/>  
                    </div>
                    <Modal
                        aria-labelledby={`${props.courseName} Certificate - Full size`}
                        aria-describedby={`This modal display full sized image of the certificate for the course of ${props.courseName}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <img src={props.certificate} className="bordered" alt={`${props.courseName} certificate`} width="80%" style={{margin:"30px", alignContent:"center"}} />
                        </Fade>
                    </Modal>
                </TreeItem>
            </TreeView>
        </div>
    )
}
