import React from 'react';
// import { Button } from 'react-bulma-components';
import Courses from '../../Components/Courses/Courses';
import Education from '../../Components/Education/Education';
import Experience from '../../Components/Experience/Experience';
// import ExtraCurricular from '../../Components/ExtraCurricular/ExtraCurricular';
import Intro from '../../Components/Intro/Intro';
import OtherActivities from '../../Components/OtherActivities/OtherActivities';
import Projects from '../../Components/Projects/Projects';
import Social from '../../Components/Social/Social';
import TechStack from '../../Components/TechStack/TechStack';

export default function Home({refs}) {
    return (
        <div  className="section is-small main-section">
            <Intro           innerRef={refs.introRef}      />
            <Education       innerRef={refs.educationRef}  />
            <Projects        innerRef={refs.projectsRef}   />
            <Experience      innerRef={refs.experienceRef} />
            <Courses         innerRef={refs.coursesRef}    />
            <TechStack       innerRef={refs.techStackRef}  />
            {/* <ExtraCurricular innerRef={refs.extraCurricularRef} /> */}
            <OtherActivities innerRef={refs.otherActivitiesRef} />
            <Social          innerRef={refs.socialRef}     />
        </div>
    )
}
