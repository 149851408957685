import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function TechStackTable(props) {
    const skills = props.techStack;
    const isDark = props.isDark;

    const techStackRow = (skill, row, column)=>{
        const Icon = skill.icon;
        const style = { height: "30px", verticalAlign: "bottom"};
        const key = `${skill.id}-${row}-${column}-${skill.name}`
        return(
            <Grid className="bordered mb-3 py-3 px-2" container direction="row" key={key} >
                <Grid item xs={2}><Icon style={style}/></Grid>
                <Grid item xs={10}><Typography variant="h6" component="h6">{skill.name}</Typography></Grid> 
            </Grid>
        );
    };

    return (
        <div>
            <Grid className="px-5 pb-2" direction="row" container  justify="space-around" spacing={2}>
                <Grid className="mb-2 " item xs={12} sm={5} md={3}>
                    {skills.slice(0,5).map((skill, index)=>techStackRow(skill, index, 0))}
                </Grid>
                <Grid className="mb-2 " item xs={12} sm={5} md={3}>
                    {skills.slice(5,10).map((skill, index)=>techStackRow(skill, index, 1))}
                </Grid>
                <Grid className="mb-2 " item xs={12} sm={5} md={3}>
                    {skills.slice(10,15).map((skill, index)=>techStackRow(skill, index, 2))}
                </Grid>
                <Grid className="mb-2 " item xs={12} sm={5} md={3}>
                    {skills.slice(15).map((skill, index)=>techStackRow(skill, index, 3))}
                </Grid>
            </Grid>
        </div>
    )
}
