import React, {useContext} from 'react'
import { Navbar} from 'react-bulma-components';
import Headroom from 'react-headroom';
import ToggleSwitch from '../Toggle/Toggle';

import StyleContext from '../../Contexts/themeContext';
import './CustomNavbar.css';
import DP from "../../DP.png";
export default function CustomNavBar(props) {
    const [isActive, setisActive] = React.useState(false);
    const {isDark} = useContext(StyleContext);  

    const themeClassModifier = isDark ?"-dark" :"";
    
    return (
    <Headroom >
        <Navbar renderAs="div" className={`px-4 custom-navbar${themeClassModifier}`}>
            <Navbar.Brand>
                <Navbar.Item href="/">
                        <img
                            alt="Rajan Gaul"
                            height="28"
                            src={DP}
                        />
                        <h1 className={`pl-4 name${themeClassModifier}`}>Rajan Gaul</h1>
                </Navbar.Item>
                <Navbar.Burger className={`custom-navbar-burger${themeClassModifier}`} onClick={()=>setisActive(!isActive)} aria-label="Navbar Menu Button"/>
            </Navbar.Brand>
            <Navbar.Menu className={`${isActive ? isDark ? "is-active -dark pl-3 pb-2" :"is-active pl-3" : ""}`}>
                <Navbar.Container align="right" className={`custom-navbar-menu-item${themeClassModifier}`}>
                    <Navbar.Item target = "_blank" rel = "noopener noreferrer" href="./Rajan_Gaul_CV.pdf">
                        <h2>Résumé</h2>
                    </Navbar.Item>  
                    <Navbar.Item onClick={() => props.scrollTo(props.refs.projectsRef)}>
                        <h2>Projects</h2>
                    </Navbar.Item>
                    <Navbar.Item onClick={() => props.scrollTo(props.refs.experienceRef)}>
                        <h2>Experience</h2>
                    </Navbar.Item>
                    <Navbar.Item onClick={() => props.scrollTo(props.refs.socialRef)}>
                        <h2>Social</h2>
                    </Navbar.Item>
                    <Navbar.Item>
                        <ToggleSwitch/>
                    </Navbar.Item>
                </Navbar.Container>
            </Navbar.Menu>
        </Navbar>
    </Headroom>
    )
}
