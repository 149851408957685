import React from 'react'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export default function CourseTree(props) {
    const renderTree = (course, index) => {
        // const label = `${index+1}. ${nbsp}${nbsp}${nbsp}${nbsp} ${course.name}`;
        const themeClassModifier = props.isDark ?"-dark" :"";

        return (
        <TableRow key={`${index+1}-${course.name}`}>
            <TableCell className={`courses-list${themeClassModifier}`} component="th" scope="row">{index+1}</TableCell>
            <TableCell className={`courses-list${themeClassModifier}`} align="right">{course.name}</TableCell>
        </TableRow>
        );
    };
    return (
        <div>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                >
                <TreeItem nodeId={`Courses`} label="Courses">
                    <Table size="small" aria-label="Courses in this specialization">
                        <TableBody>
                            {props.courses.map(renderTree)}
                        </TableBody>
                    </Table>
                </TreeItem>
            </TreeView>
        </div>
    )
}
