import React, {useContext} from 'react'
import Timeline from '@material-ui/lab/Timeline';
import { Container, Heading } from 'react-bulma-components'
import './Education.css';
import { educationDetails } from '../../Data/data';
import TimelineCard from './TimelineCard';
import StyleContext from '../../Contexts/themeContext';

export default function Education(props) {
    const {isDark} = useContext(StyleContext);  

    const themeClassModifier = isDark ?"-dark" :"";
    return (
        <div ref={props.innerRef}>
            <section className="section is-small">
                <Container>
                    <Heading textAlign="left" className={`sec-head${themeClassModifier}`}>
                        Education
                    </Heading>
                    <Timeline align="left" >
                        {educationDetails.map((detail, index)=><TimelineCard key={detail.key} details={detail} isLast={index === educationDetails.length-1}/>)}
                    </Timeline>
                </Container>
            </section>
        </div>
    )
}
