import React, {useContext} from 'react'
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import { Heading } from 'react-bulma-components';
import CourseTree from "./CourseTree";
import Certificate from "./Certificate";
import Chip from '@material-ui/core/Chip';

import StyleContext from '../../Contexts/themeContext';

export default function CourseDetails(props) {
    const course = props.course;
    // const nbsp = '\u00A0';
    const {isDark} = useContext(StyleContext);  

    const themeClassModifier = isDark ?"-dark" :"";
    return (
        <div className="py-2">
            <Accordion  className={`bordered timeline-accordian${themeClassModifier}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={`accordian-expand-icon${themeClassModifier}`}/>}
                  aria-controls= {`${course.name}-panel1a-content`}
                  id={`${course.name}-panel1a-header`}
                  >
                    <Grid container direction="row" justify="space-between">
                        <Grid item xs={12} sm={10}>
                        <Typography variant="h6" component="h3">{course.name}</Typography>
                        <Heading  heading className="non-capital-heading">{course.offeredBy}</Heading>
                        </Grid>
                        <Grid  className="pt-1" item xs={12} sm={2}>
                            <Heading heading className={`course-date${themeClassModifier}`}>{course.completionDate}</Heading>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container direction="column">
                        <Grid item>
                        <Heading heading className={`pl-1 instructor-name${themeClassModifier}`}><a className="link" href={course.instructorUrl}  target = "_blank" rel = "noopener noreferrer">Instructor: {course.instructor}</a></Heading>
                        </Grid>
                        <Grid item >
                            {course.isSpecialization && <CourseTree courses={course.courseList}  isDark={isDark}/>}
                        </Grid>
                        <Grid item>
                            <Certificate certificate={course.certificate} courseName={course.name} isDark={isDark}/>
                        </Grid>
                        <Grid item className="pt-2">
                            {course.technologies.map((tech, index)=><Chip key={`${course.name}-${tech}-${index}`} className={`mr-3 chip${themeClassModifier}`} label={tech} variant="outlined" size="small"/>)}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
